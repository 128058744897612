<template>
  <!-- Traitement phyto et ferti -->
  <form @submit.prevent="onSubmit()">
    <div class="organ-subview">
      <div class="tabs-material-subtitle no-padding-bottom">
        <Container>
          <h2>Conditions de traitement</h2>
        </Container>
      </div>

      <Section>
        <Container>
          <div class="form grid">
            <Input id="materielutilise" label="Matériel utilisé" />
            <Input id="reference" label="Référence" />
            <Input id="autre" label="Autre" />
          </div>

          <Section>
            <h3>Contrôle pulvérisateur et ses éléments avant application</h3>

            <Radio
              id="controle_pulveristeur"
              :items="ficheControlePulverisateur"
              inputStyle="inline"
            />

            <Input id="controlepulve_commentaires" type="textarea" placeholder="Commentaires" />
          </Section>

          <h3>Heure</h3>
          <div class="form grid">
            <Input id="heure_debut" label="Début" type="time" />
            <Input id="heure_fin" label="Fin" type="time" />
          </div>

          <Section>
            <h3>Stade culture</h3>

            <div class="form grid">
              <SelectExtended
                id="stade_id"
                optionKey="id"
                optionValue="designation_calculee"
                apiEndpoint="dictionnaire/stade"
                :apiParams="stade.apiParams"
              />
            </div>
          </Section>

          <Section>
            <h3>État végétatif culture</h3>

            <Input id="etat_vegetatif_culture" type="textarea" placeholder="Commentaires" />
          </Section>

          <Section>
            <h3>Température</h3>
            <div class="grid margin-bottom">
              <div class="col-tmp">
                <Input
                  id="temperature_debut"
                  label="Début"
                  type="number"
                  input-after="C°"
                  :options="{
                    step: 0.001
                  }"
                />
              </div>
              <div class="col-tmp">
                <Input
                  id="temperature_fin"
                  label="Fin"
                  type="number"
                  input-after="C°"
                  :options="{
                    step: 0.001
                  }"
                />
              </div>
            </div>
            <h3>Hygrométrie</h3>
            <div class="grid">
              <div class="col-tmp">
                <Input
                  id="hygrometrie_debut"
                  label="Début"
                  type="number"
                  input-after="%"
                  :options="{min: 0, max: 100, step: 0.001}"
                />
              </div>
              <div class="col-tmp">
                <Input
                  id="hygrometrie_fin"
                  label="Fin"
                  type="number"
                  input-after="%"
                  :options="{min: 0, max: 100, step: 0.001}"
                />
              </div>
            </div>

          </Section>

          <!-- Vent -->
          <Section>
            <h3>Vent</h3>
            <div class="form grid">
              <SelectExtended
                id="vitesse_vent_id"
                optionKey="id"
                label="&nbsp;"
                optionValue="valeur"
                :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
                  'vitessevent'
                  )}/valeur`"
              />
              <Input
                id="vitessevent_min"
                label="Début"
                type="number"
                input-after="km/h"
                :options="{
                    step: 0.001
                }"
              />
              <Input
                id="vitessevent_max"
                label="Fin"
                type="number"
                input-after="km/h"
                :options="{
                    step: 0.001
                }"
              />
            </div>
          </Section>

          <!-- Etat surface du sol -->
          <Section>
            <div class="form grid">
              <div class="div">
                <h3>État surface du sol</h3>
                <SelectExtended
                  id="etat_surface_sol_id"
                  optionKey="id"
                  optionValue="valeur"
                  :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
                  'etatsurfacesol'
                  )}/valeur`"
                />
              </div>
              <div class="div">
                <h3>Humidité du sol</h3>
                <SelectExtended
                  id="humidite_sol_id"
                  optionKey="id"
                  optionValue="valeur"
                  :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
                  'humiditesol'
                  )}/valeur`"
                />
              </div>
            </div>
          </Section>

          <!-- Résidus récolte -->
          <Section>
            <div class="grid">
              <div class="col-tmp">
                <h3>Résidus récolte</h3>
                <SelectExtended
                  id="residus_recolte_id"
                  optionKey="id"
                  optionValue="valeur"
                  :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
              'residusrecolte'
              )}/valeur`"
                />
              </div>
            </div>
          </Section>

          <!-- Ensoleillement -->
          <Section>
            <div class="grid">
              <div class="col-tmp">
                <h3>Ensoleillement</h3>
                <SelectExtended
                  id="ensoleillement_id"
                  optionKey="id"
                  optionValue="valeur"
                  :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
              'ensoleillement'
              )}/valeur`"
                />
              </div>
            </div>
          </Section>

          <!-- Nébulosité -->
          <Section>
            <h3>Nébulosité</h3>
            <div class="form grid">
              <SelectExtended
                id="nebulosite_id"
                optionKey="id"
                optionValue="valeur"
                label="&nbsp;"
                :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
                'nebulosite'
                )}/valeur`"
              />

              <Input
                id="nebulosite_mesure"
                label="Nébulosité"
                input-after="%"
                type="number"
                :options="{min: 0, max: 100}"
              />
            </div>
          </Section>

          <!-- Pluie dans les 24h -->
          <Section>
            <h3>Pluie dans les 24h</h3>
            <div class="form grid">
              <Radio
                id="pluie_24h"
                :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                ]"
                inputStyle="inline"
              />
              <Input
                id="pluie_quantite"
                label="Quantité"
                input-after="mm"
                type="number"
                :options="{min: 0}"
              />
            </div>
          </Section>

          <!-- Rosée au moment du traitement -->
          <Section>
            <div class="grid">
              <div class="col-tmp">
                <h3>Rosée au moment du traitement</h3>
                <SelectExtended
                  id="rosee_id"
                  optionKey="id"
                  optionValue="valeur"
                  :apiEndpoint="`dictionnaire/referentiel/${helperService.getReferentialByUid(
                'rosee'
                )}/valeur`"
                />
              </div>
            </div>
          </Section>

          <!-- Pression des buses -->
          <Section>
            <h3>Pression des buses</h3>
            <div class="form grid">
              <Input
                id="pressionbuses_quantite"
                label="Quantité"
                type="number"
                input-after="bars"
                :options="{min:0, step: 0.001}"
              />
              <Input id="typebuses" label="Type de buses" />
            </div>
          </Section>

          <!-- N° Modalités traités -->
          <Section v-if="modalities.length">
            <h3>N° modalités traités</h3>
            <div class="modalities-badge">
              <Btn
                v-for="(modality, index) in modalities.sort((a,b) => a.ordre - b.ordre)"
                :key="index"
                @click="selectModality(modality.id)"
                :text="modality.ordre.toString()"
                :color="ficheModalities.findIndex((fm) => fm === modality.id) === -1
                  ? 'default'
                  : 'primary'
                "
                size="lg"
                round
              />
            </div>
          </Section>

          <!-- Observations cible -->
          <Section>
            <h3>Observations cible</h3>
            <Input id="observations_cible" type="textarea" placeholder="Commentaires" />
          </Section>

          <!-- Observations cible -->
          <Section>
            <h3>Observations traitement</h3>
            <Input id="observations_traitement" type="textarea" placeholder="Commentaires" />
          </Section>

          <!-- Validations traitement -->
          <Section>
            <h3>Validation traitement</h3>
            <div class="form grid">
              <Input id="validation_trigramme" label="Trigramme" />
              <Input id="validation_date" type="date" label="Date" />
            </div>
          </Section>
        </Container>
      </Section>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn text="Annuler" :to="{ name: 'essaiCalendar', params: {id: $route.params.id} }" />
          <Btn btnType="submit" text="Enregistrer" color="primary" />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
/* eslint-disable no-param-reassign */
import Btn from '@/components/base/Btn.vue'
import Input from '@/components/form/Input.vue'
import Container from '@/components/layout/Container.vue'
import Section from '@/components/layout/Section.vue'
import Radio from '@/components/form/Radio.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'

export default {
  name: 'ConditionsTraitementSubview',
  components: {
    SelectExtended,
    Radio,
    Section,
    Container,
    Input,
    Btn,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    const validationSchema = this.yup.object().shape({
      materielutilise: this.yup.string().nullable(),
      reference: this.yup.string().nullable(),
      autre: this.yup.string().nullable(),
      controle_pulveristeur: this.yup.object().nullable(),
      controlepulve_commentaires: this.yup.string().nullable(),
      heure_debut: this.yup.string().nullable(),
      heure_fin: this.yup.string().nullable(),
      stade_id: this.yup.object().nullable(),
      etat_vegetatif_culture: this.yup.string().nullable(),
      temperature_debut: this.yup.number().nullable(),
      temperature_fin: this.yup.number().nullable(),
      hygrometrie_debut: this.yup.number().nullable(),
      hygrometrie_fin: this.yup.number().nullable(),
      vitesse_vent_id: this.yup.object().nullable(),
      vitessevent_min: this.yup.number().nullable(),
      vitessevent_max: this.yup.number().nullable(),
      etat_surface_sol_id: this.yup.object().nullable(),
      humidite_sol_id: this.yup.object().nullable(),
      residus_recolte_id: this.yup.object().nullable(),
      ensoleillement_id: this.yup.object().nullable(),
      nebulosite_id: this.yup.object().nullable(),
      nebulosite_mesure: this.yup.number().nullable(),
      pluie_24h: this.yup.boolean().nullable(),
      pluie_quantite: this.yup.number().nullable(),
      rosee_id: this.yup.object().nullable(),
      pressionbuses_quantite: this.yup.number().nullable(),
      typebuses: this.yup.string().nullable(),
      observations_cible: this.yup.string().nullable(),
      observations_traitement: this.yup.string().nullable(),
      validation_trigramme: this.yup.string().nullable(),
      validation_date: this.yup.date().nullable(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(validationSchema)
    const onSubmit = this.formService.handleSubmit((values) => {
      this.handleSubmit(values)
    })

    return {
      modal: {
        edit: false,
      },
      modalData: {},
      fiche: {},
      modalities: {},
      ficheModalities: [],
      ficheControlePulverisateur: [],
      protocoleId: null,
      stade: {
        apiParams: {
          sort: 'ordre.ASC',
        },
      },

      isSubmitting,
      errors,
      onSubmit,
      validationSchema,
    }
  },

  mounted() {
    this.getFiche()
    this.getModalities()
    this.getControlePulverisateur()
  },

  methods: {
    openModal(modal, data) {
      this.modalData = data
      this.modal[modal] = true
    },

    selectModality(id) {
      if (this.ficheModalities.findIndex((modality) => modality === id) === -1) {
        this.ficheModalities.push(id)
      } else {
        this.ficheModalities.splice(
          this.ficheModalities
            .findIndex(
              (modality) => modality === id,
            ),
          1,
        )
      }
    },

    getModalities() {
      this.fetchService.get(`essai/${this.$route.params.id}`).then((response) => {
        this.protocoleId = response.data.protocole.id
        this.fetchService
          .get(`protocole/${this.protocoleId}/modalite`, {
            limit: 0,
          })
          .then((modalities) => {
            this.modalities = modalities.data
            this.fetchService.get(`protocole/${this.protocoleId}`).then((protocole) => {
              const cultures = protocole.data.cultures.map((item) => item.uid)
              if (cultures.length > 0) {
                this.stade.apiParams = {
                  sort: 'ordre.ASC',
                  filters: `culture.uid:in(${cultures})`,
                }
              }
            })
          })
      })
    },

    getControlePulverisateur() {
      this.fetchService
        .get(`dictionnaire/referentiel/${this.helperService.getReferentialByUid(
          'controlepulverisateur',
        )}/valeur`)
        .then((response) => {
          response.data.forEach((r) => {
            this.ficheControlePulverisateur.push({
              label: r.valeur,
              value: r.id,
            })
          })
        })
    },

    getFiche() {
      this.emitter.emit('open-loader')

      this.fetchService
        .get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/conditions_traitement`)
        .then((response) => {
          const data = response.data[0]
          this.fiche = data

          this.schema = this.formService.populateShema(data)

          this.schema.controle_pulveristeur = data?.controle_pulveristeur?.id
          if (data.ensoleillement) {
            this.schema.ensoleillement_id = {
              key: data?.ensoleillement?.id,
              value: data?.ensoleillement?.valeur,
            }
          }
          if (data.etat_surface_sol) {
            this.schema.etat_surface_sol_id = {
              key: data?.etat_surface_sol?.id,
              value: data?.etat_surface_sol?.valeur,
            }
          }
          if (data.humidite_sol) {
            this.schema.humidite_sol_id = {
              key: data?.humidite_sol?.id,
              value: data?.humidite_sol?.valeur,
            }
          }
          if (data.nebulosite) {
            this.schema.nebulosite_id = {
              key: data?.nebulosite?.id,
              value: data?.nebulosite?.valeur,
            }
          }
          if (data.vitesse_vent) {
            this.schema.vitesse_vent_id = {
              key: data?.vitesse_vent?.id,
              value: data?.vitesse_vent?.valeur,
            }
          }
          if (data.residus_recolte) {
            this.schema.residus_recolte_id = {
              key: data?.residus_recolte?.id,
              value: data?.residus_recolte?.valeur,
            }
          }
          if (data.rosee) {
            this.schema.rosee_id = {
              key: data?.rosee?.id,
              value: data?.rosee?.valeur,
            }
          }
          if (data.stade) {
            this.schema.stade_id = {
              key: data?.stade?.id,
              value: `${data?.stade?.code_bbch} - ${data?.stade?.designation_court}`,
            }
          }
          if (data.modalites) {
            this.ficheModalities = data.modalites.map((modality) => modality.id)
          }
          if (data.validation_date) {
            this.schema.validation_date = data.validation_date.split(' ').shift()
          }

          this.formService.setFormValues(this.schema)

          this.emitter.emit('close-loader')
        })
    },

    handleSubmit(values) {
      values.controle_pulveristeur = values?.controle_pulveristeur?.key ?? null
      values.ensoleillement_id = values?.ensoleillement_id?.key ?? null
      values.etat_surface_sol_id = values?.etat_surface_sol_id?.key ?? null
      values.humidite_sol_id = values?.humidite_sol_id?.key ?? null
      values.nebulosite_id = values?.nebulosite_id?.key ?? null
      values.vitesse_vent_id = values?.vitesse_vent_id?.key ?? null
      values.residus_recolte_id = values?.residus_recolte_id?.key ?? null
      values.rosee_id = values?.rosee_id?.key ?? null
      values.stade_id = values?.stade_id?.key ?? null
      values.validation_date = this.helperService.formatDateForApi(
        values?.validation_date,
      )
      values.modalites = this.ficheModalities.map((fm) => ({
        id: fm,
      }))

      this.fetchService
        .put(
          `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/conditions_traitement/${this.fiche.id}`, values,
        )
        .then((response) => {
          this.fiche = response.data
          this.taskService.handleExperimentalTaskRedirect(this.$route)
        })
      this.modal.edit = false
    },
  },
}
</script>

<style lang="scss" scoped>
.modalities-badge {
  display: flex;
  gap: $gutter-half;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background: $color-gray-lightest;
  }
  > div.filled {
    color: white;
    background: $color-primary;
  }
}
</style>
